import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { AppBar, Tabs, Tab, Box } from "@mui/material";
import Home from "./Home";
import PrivacyPolicy from "./PrivacyPolicy";
import Footer from "./Footer";

function App() {
  return (
    <Router>
      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#e0e0e0", // Darker background color
        }}
      >
        <AppBar position="static">
          <Tabs>
            <Tab component={Link} to="/" label="Home" />
            <Tab component={Link} to="/privacy-policy" label="Privacy Policy" />
          </Tabs>
        </AppBar>

        <Box p={2} flex="1" sx={{ backgroundColor: "#e0e0e0" }}>
          {" "}
          {/* Darker background color */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </Box>

        <Footer />
      </Box>
    </Router>
  );
}

export default App;
