import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";
import ExpenseItIcon from "./assets/expenseit-icon.png"; // Update the path based on where you saved the icon

function Home() {
  return (
    <Box p={4}>
      <Typography variant="h4" gutterBottom>
        Our Apps
      </Typography>
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar src={ExpenseItIcon} alt="ExpenseIt Icon" />
          </ListItemAvatar>
          <ListItemText primary="ExpenseIt" />
        </ListItem>
      </List>
    </Box>
  );
}

export default Home;
