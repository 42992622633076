import React from "react";
import { Box, Typography } from "@mui/material";

function PrivacyPolicy() {
  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Last Updated: 10/26/2024
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. Introduction
      </Typography>
      <Typography paragraph>
        Welcome to ExpenseIt ("we," "our," "us"). We are committed to protecting
        your privacy and ensuring that your personal information is handled in a
        safe and responsible manner. This Privacy Policy outlines how we
        collect, use, disclose, and safeguard your information when you use our
        mobile application (the "App"). By using the App, you consent to the
        practices described in this policy.
      </Typography>

      <Typography variant="h6" gutterBottom>
        2. Information We Collect
      </Typography>
      <Typography paragraph>
        We may collect and process the following types of information:
        <ul>
          <li>
            Personal Information: While using our App, you may provide us with
            personal information such as your name, email address, and other
            contact details. However, our App does not require you to provide
            personal information to use its core features.
          </li>
          <li>
            Usage Data: We automatically collect information about how you
            interact with the App. This includes your device type, operating
            system, IP address, device identifiers, and usage patterns.
          </li>
          <li>
            Advertising Data: We use Google AdMob to serve advertisements within
            the App. AdMob may collect information such as your device ID, IP
            address, device type, operating system, and browsing information to
            provide personalized ads.
          </li>
        </ul>
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. How We Use Your Information
      </Typography>
      <Typography paragraph>
        We use the collected information for the following purposes:
        <ul>
          <li>
            To Provide and Improve the App: Ensuring the App functions correctly
            and enhancing user experience.
          </li>
          <li>
            Advertising: Displaying personalized advertisements through Google
            AdMob.
          </li>
          <li>
            Analytics: Understanding how users interact with the App to improve
            our services.
          </li>
          <li>
            Communication: Sending updates, newsletters, or promotional
            materials, if you have opted in to receive them.
          </li>
        </ul>
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. Sharing Your Information
      </Typography>
      <Typography paragraph>
        We may share your information in the following circumstances:
        <ul>
          <li>
            With Service Providers: We may share your information with
            third-party service providers that perform services on our behalf,
            such as Google AdMob for advertising.
          </li>
          <li>
            Legal Requirements: We may disclose your information if required to
            do so by law or in response to valid legal requests.
          </li>
          <li>
            Business Transfers: In the event of a merger, acquisition, or sale
            of all or part of our assets, your information may be transferred as
            part of that transaction.
          </li>
        </ul>
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. Third-Party Services
      </Typography>
      <Typography paragraph>
        <ul>
          <li>
            Google AdMob: We use Google AdMob to serve advertisements within the
            App. AdMob's Privacy Policy can be found here.
          </li>
          <li>
            App Store and Google Play: Distribution through the Apple App Store
            and Google Play Store may involve data collection as per their
            respective privacy policies.
          </li>
        </ul>
      </Typography>

      <Typography variant="h6" gutterBottom>
        6. Your Rights and Choices
      </Typography>
      <Typography paragraph>
        Depending on your location, you may have the following rights regarding
        your personal information:
        <ul>
          <li>
            Access and Correction: You can request access to the personal
            information we hold about you and request corrections if necessary.
          </li>
          <li>
            Deletion: You can request the deletion of your personal information,
            subject to certain exceptions.
          </li>
          <li>
            Opt-Out: You can opt out of receiving promotional communications
            from us by following the unsubscribe instructions included in such
            communications.
          </li>
          <li>
            Do Not Track: Our App does not respond to Do Not Track (DNT)
            signals.
          </li>
        </ul>
      </Typography>

      <Typography variant="h6" gutterBottom>
        7. Data Security
      </Typography>
      <Typography paragraph>
        We implement appropriate technical and organizational measures to
        protect your personal information against unauthorized access,
        alteration, disclosure, or destruction. However, no method of
        transmission over the internet or electronic storage is 100% secure, and
        we cannot guarantee absolute security.
      </Typography>

      <Typography variant="h6" gutterBottom>
        8. International Data Transfers
      </Typography>
      <Typography paragraph>
        Your information may be transferred to and maintained on servers located
        outside your country of residence. By using the App, you consent to such
        transfers in accordance with this Privacy Policy.
      </Typography>

      <Typography variant="h6" gutterBottom>
        9. Children's Privacy
      </Typography>
      <Typography paragraph>
        Our App is not intended for use by children under the age of 13. We do
        not knowingly collect personal information from children under 13. If
        you become aware that a child has provided us with personal information,
        please contact us immediately.
      </Typography>

      <Typography variant="h6" gutterBottom>
        10. Changes to This Privacy Policy
      </Typography>
      <Typography paragraph>
        We may update this Privacy Policy from time to time. Any changes will be
        effective immediately upon posting the revised policy within the App. We
        encourage you to review this Privacy Policy periodically for any
        updates.
      </Typography>
    </Box>
  );
}

export default PrivacyPolicy;
